@import "custom";

.content-modal {
  background-color: $white;
  max-inline-size: 1160px;
  padding: 120px 85px 80px;
  position: relative;

  @include media-breakpoint-down(md) {
    inline-size: calc(100vw - 48px);
    padding: 80px 40px 60px;
  }

  @include media-breakpoint-down(sm) {
    inline-size: calc(100vw - 24px);
  }

  &-title-col {
    h2 {
      font-size: 60px;
    }
  }

  &-image-col {
    img {
      inline-size: 100%;
    }
  }

  p {
    font-size: 20px;
    line-height: 1.5em;
  }

  &-directions-col,
  &-ingredients-col {
    position: relative;

    h3 {
      font-size: 20px;
      font-weight: 500;
      margin: 0 0 30px;
    }

    ol {
      margin: 0 0 0 15px;
      max-inline-size: 85%;
      padding: 0;

      li {
        font-size: 20px;
        line-height: 1.4em;
        margin: 0 0 20px;

        p {
          margin: 0;
        }
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        font-size: 20px;
        margin: 0;

        p {
          line-height: 2em;
          margin: 0;
        }
      }
    }
  }

  &-header {
    margin-block-end: 50px;
  }

  &-content {
    font-size: 20px;
    margin: 50px 0 40px;
  }

  &-close-button {
    background-color: $black;
    border: 1px solid transparent;
    inset-block-start: 30px;
    inset-inline-end: 40px;
    outline: none !important;
    padding: 0;
    position: absolute;

    &:focus {
      border: 1px solid $white;
    }
  }
}
